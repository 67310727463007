<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            "
          >
            <h4 class="mb-sm-0">Marketing</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Form Marketing</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Edit Pengguna</h4>
              <div class="flex-shrink-0">
                <router-link
                  :to="{ name: 'marketing' }"
                  type="button"
                  class="btn btn-warning waves-effect waves-light"
                  ><i
                    class="bx bx-chevrons-left font-size-16 align-middle me-2"
                  ></i>
                  Kembali
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <form class="" @submit.prevent="submit">
                <div class="form-group mb-3">
                  <label class="form-label">Nama</label>
                  <input
                    v-model="marketing.name"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan nama"
                    required
                  />
                </div>

                <div class="form-group mb-3">
                  <label class="form-label">Email</label>
                  <input
                    v-model="marketing.email"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan email"
                    required
                  />
                </div>
                <div class="alert alert-info alert-borderless" role="alert">
                  Password default untuk marketing baru adalah
                  <strong>123456</strong>
                </div>
                <div class="float-end">
                  <router-link
                    :to="{ name: 'marketing' }"
                    type="button"
                    class="btn btn-warning waves-effect waves-light me-2"
                    ><i
                      class="bx bx-chevrons-left font-size-16 align-middle me-2"
                    ></i>
                    Kembali
                  </router-link>
                  <button
                    :disabled="load"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light"
                  >
                    <i
                      class="bx font-size-16 align-middle me-2"
                      :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"
                    ></i>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpService from "../../services/http-service";
import { endpoint } from "../../host";
import Swal from "sweetalert2";
import errorService from "../../services/error-service";
export default {
  components: {},
  created() {
    var params = this.$route.params;
    if (params.id !== undefined) {
      this.assignParams(params);
    }
  },
  data() {
    return {
      title: null,
      load: false,
      marketing: {
        id: null,
        name: null,
        email: null,
        password: "123456",
        password_confirmation: "123456",
        _method: "POST",
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.load = true;
        let res = await httpService.post(endpoint.marketing, this.marketing, {
          headers: httpService.authHeader(),
        });
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.$router.push({
            name: "marketing",
            params: {
              reload: true,
            },
          });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    assignParams(params) {
      console.log(params.user);
      this.marketing.id = params.id;
      this.marketing.name = params.user.name;
      this.marketing.email = params.user.email;
      this.marketing._method = params.id ? "PUT" : "POST";
    },
  },
};
</script>

<style>
</style>